#baker-calc {
  box-sizing: border-box;
  font-family: sans-serif;
}

#baker-calc *, #baker-calc *:before, #baker-calc *:after {
  box-sizing: inherit;
}

#baker-calc .calc {
  border: 1px solid #e2ecf3;

  display: grid;
  grid-template-columns: 50px 3fr 3fr 2fr;
}

.cell-wide {
  grid-column: 1 / span 2;
}

.cell {
  padding: 10px 8px;
  display: grid;
  align-items: center;
}

.cell-inner {
  border-bottom: 1px solid #e2ecf3;
  border-right: 1px solid #e2ecf3;
}

.cell-outer {
  border-bottom: 1px solid #e2ecf3;
}

.cell-header {
  border-top: 1px solid #e2ecf3;
  border-bottom: 1px solid #e2ecf3;
  background-color: #f4f8fb;
  color: #15171a;
  font-weight: bold;
}

.separator {
  margin-bottom: 10px;
  border: 0;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

#baker-calc input {
  width: 100%;
  padding: 6px 4px;
  border: 1px solid #ddd;
}

#baker-calc button {
  padding: 7px 12px;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.button-add {
  background-color: #5cb85c;
  border: 1px solid #4cae4c;
}

.button-delete {
  background-color: #d9534f;
  border: 1px solid #d53f3a;
}

.error {
  color: red;
  text-decoration: underline;
}